import React from 'react';
import Layout from './Layout';
import Parser from 'html-react-parser';
import {graphql} from 'gatsby';
import makeDate from '../utils/makeDate';
import stripTags from '../utils/stripMarkup';

// Import styling components
import styled from 'styled-components';

// Data 
export const query = graphql`
	query($postId: ID!) {
		cms {
			posts(where: {id: $postId}) {
				id
				title
				body
				postedat
			}
		}
	}
`;

// Styled components
const PostContent = styled.article`
	box-sizing: border-box;
	margin: auto;
	max-width: var(--line-length);
	width: 100%;

	a {
		color: var(--blue);
		text-decoration: none;
	}
	a:hover { text-decoration: underline; }

	blockquote {
		border: 1px solid var(--black);
		color: rgba(0, 0, 0, 0.85);
		font-size: calc(15px + 0.25vw);
		margin: 0;
		padding: 0.5rem;
	}

	figcaption { 
		font-size: calc(13px + 0.25vw);
		text-align: center;
	}

	figure { margin: 0;}

	h1, h2, h3, h4, h5, h6 { 
		line-height: 2.75rem; 
		word-break: break-word;
	}

	img {
		box-shadow: var(--shadow);
		display: block;
		margin: 2rem auto;
		width: 100%;
	}

	@media (max-width: 620px) {
		h1, h2, h3, h4, h5, h6 { max-width: 275px; }
	}
`;

const PostDate = styled.p`
	color: rgba(0, 0, 0, 0.5);
	font-weight: bold;
	margin-bottom: 1.5rem;
	padding: 0;
`;

const PostFooter = styled.footer`
	font-weight: bold;
	padding: 2rem 0;
	text-align: center;
`;

const PostTitle = styled.strong`
	margin: 0;
	padding: 0;
`;

// Stateless functional components
const PostWrapper = props => (
	<PostContent>
		<PostTitle>{props.post.title}</PostTitle>
		<PostDate>Posted on {makeDate(props.post.postedat)}</PostDate>
		{Parser(props.post.body)}
	</PostContent>
);

export default ({data}) => (
	<Layout pageTitle={data.cms.posts[0].title} metaKeywords={data.cms.posts[0].title.replace(/ /g, ',')} metaDescription={stripTags(data.cms.posts[0].body.slice(0, 500))}>
		<PostWrapper post={data.cms.posts[0]} key={data.cms.posts[0].id} />
		<PostFooter>
			<p>Did you enjoy this post?</p>
			<p>Follow me on social media to keep up with<br/>what I'm doing and tell your friends about my blog!</p>
		</PostFooter>
	</Layout>
);
